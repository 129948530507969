import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #222;

  > div.topbar{
    background-color: #003267;
    justify-content: space-evenly;
    margin-bottom: 1em;
    padding: 1em;
  }

  > div.topbar > img{
    -webkit-filter: invert(1);
    filter: invert(100%);
    width: 3em;
  }

  > div.topbar > h1{
    line-height: 0;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }

  > div.topbar > *{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  > div.topbar > div.daycounter{
    width: 3em;
    background-color: #004896;
    color: #fff;
    padding: .5em .5em;
    border: none;
    border-radius: 5px;
    font-size: 1em;
  }

  > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  > div > div{ 
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    //min-width: 200px;
    padding: 10px;
    width: 40%;  
    list-style: none;
  }

  > div > div > div{
    background-color: #004896;
    color: #fff;
    padding: .5em .5em;
    border: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 1em;
    display: flow-root list-item;
    width: 100%;
  }

  > div > select {
    background-color: #003267;
    color: #fff;
    padding: 1em 20px;
    margin-bottom: 1em;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    width: 80%;
  }
  
  > div > input {
    background-color: #003267;
    color: #fff;
    padding: 1em 20px;
    margin-bottom: 1em;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    width: 80%;
  }
`;

const Button = styled.button`
  background-color: #003267;
  color: #fff;
  padding: 1em 20px;
  border: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 2em;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const Counter = ({ id, count, handleIncrement, selectedContext, handleContextChange, dropdownOptions, name }) => (
  <div>
    <div>{count}</div>
    <Button onClick={() => handleIncrement(id)}>{name}</Button>
  </div>
);

const App = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const [counters, setCounters] = useState([]);
  const [dayliecount, setDayliecount] = useState(0);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedContext, setSelectedContext] = useState(-2);
  const [showInput, setshowInput] = useState(false);
  const [newOptionName, setNewOptionName] = useState('');
  const [APIKey, setAPIKey] = useState('');
  const [contextMap, setContextMap] = useState({}); // Map context to counters

  async function refreshCount() {
    try {
      const countsResponse = await fetch('api/getTotalCounts?' + new URLSearchParams({
        'API_KEY': APIKey,
      }));

      if (countsResponse.ok) {
        const countsData = await countsResponse.json();
        setCounters(countsData.counts);

        // Initialize the context map
        const contextMapData = {};
        countsData.counts.forEach((counter) => {
          contextMapData[counter.id] = 'context1'; // Set default context for each counter
        });
        setContextMap(contextMapData);

        setDayliecount(countsData.dayliecount);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }

  async function fetchInitialData() {
    try {
      await refreshCount();

      const optionsResponse = await fetch('api/getDropdownOptions?' + new URLSearchParams({
        'API_KEY': APIKey,
      }));

      if (optionsResponse.ok) {
        const optionsData = await optionsResponse.json();

        setDropdownOptions(optionsData.options);
        if(selectedContext === -2){
          setSelectedContext(optionsData.options[0].option_id);
        }
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }

  // Fetch the initial counts and dropdown options from the backend when the component mounts
  useEffect(() => {
    if(searchParams.get("API_KEY")){
      setAPIKey(searchParams.get("API_KEY"));
    }else{
      // Retrieve the APIKey from localStorage
      const retrievedValue = localStorage.getItem('API_KEY');
      if (retrievedValue) {
        setAPIKey(retrievedValue);
      }
    }

    const retrievedLastContext = localStorage.getItem('activeContext');
    if(retrievedLastContext){
      setSelectedContext(retrievedLastContext);
    }

    document.body.style.backgroundColor = '#222';
  }, []);

  // Fetch the initial counts and dropdown options from the backend when the component mounts
  useEffect(() => {
    // Store APIKey in localStorage
    localStorage.setItem('API_KEY', APIKey);
    fetchInitialData();
  }, [APIKey]);

  useEffect(() => {
    // Store last active context in localStorage
    localStorage.setItem('activeContext', selectedContext);
  }, [selectedContext])
  

  async function getCurrentPosition() {
    try {
      var getPosition = function (options) {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
      }
      
      let variable = await getPosition()
      return {
        latitude: variable.coords.latitude,
        longitude: variable.coords.longitude,
      };
    } catch (error) {
      console.error('Error getting location:', error);
    }
  };
  
  const handleIncrement = async (id) => {
    // Handle increment for the specific counter (add your logic here)

    let localContext = selectedContext;

    if(selectedContext == '-1'){
      console.log('Create new context');
      console.log(newOptionName);
      const response = await fetch('api/dropdownOption', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'Name': newOptionName, 
          'API_KEY': APIKey
        })
      });
      if (response.ok) {
        const responseid = await response.json();
        console.log(responseid.id);
        await fetchInitialData();
        setSelectedContext(responseid.id);
        localContext = responseid.id;
      }
    }

    // Store count data locally when offline
    const timestamp = new Date().toISOString();
    let locationData = {latitude:null, longitude:null};

    var options = {
      enableHighAccuracy: true,
      timeout: 7000,
      maximumAge: 0
    };

    function success(pos) {
      var crd = pos.coords;
      console.log('Successfully determined a user position:', crd);
    
      console.log('Your current position is:');
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
    }
    
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    try {
      locationData = await getCurrentPosition(success, error, options);
    } catch (error) {
      console.error(error);
    }

    if(!locationData){
      locationData = {latitude:null, longitude:null};
    }

    const countData = {
      counterId: id,
      timestamp,
      context: localContext,
      location: locationData, // Replace with actual location data
    };

    console.log(countData);

    if (navigator.onLine | true) {
      try{
        const response = await fetch('api/count', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            'counts': [countData], 
            'API_KEY': APIKey
          })
        });

        if (!response.ok) {
          console.error('Fetch request failed with status:', response.status);
          return;
        }

        await refreshCount();
      }catch(error){
        console.error(error)
      }
    }else{
      // save to local storage    
      // offlineCountData.push(countData);
      console.log('Store to offline');
      console.log('No offline storage');
      //console.log(offlineCountData);
    }
  };

  // Function to handle context changes for counters
  const handleContextChange = (select) => {
    setSelectedContext(Number(select));
 /*   setContextMap((prevContextMap) => ({
      ...prevContextMap,
      [id]: selectedContext,
    })); */
  };


  return (
    <Container>
      <div class="topbar">
        <img src={process.env.PUBLIC_URL + 'Ginco.svg'}></img>
        <h1>GINCO</h1>
        <div class="daycounter">{dayliecount}</div>
      </div>
      <div>
      <select onChange={(e) => handleContextChange(e.target.value)} value={selectedContext}>
        {dropdownOptions.map((option) => (
          <option key={option.option_id} value={option.option_id}>
            {option.option_name}
          </option>
        ))}
        <option key="-1" value="-1">
          other
        </option>
      </select>

      <input onChange={(e) => setNewOptionName(e.target.value)}  type={(selectedContext == '-1') ? 'text' : 'hidden'}></input>

      {counters.map((counter) => (
        <Counter
          key={counter.counter}
          id={counter.counter}
          count={counter.total_count}
          name={counter.Name}
          selectedContext={contextMap[counter.counter]}
          handleIncrement={handleIncrement}
          handleContextChange={handleContextChange}
          dropdownOptions={dropdownOptions}
        />
      ))}

      <input onChange={(e) => setAPIKey(e.target.value)} placeholder="API Key" value={APIKey} type="text"></input>
    </div>
    </Container>
  );
};

export default App;
